/*
 *
 * Users reducer
 *
 */

import { fromJS, List, Map } from 'immutable'
import { UserRecord } from 'records'
import {
  CLEAR_FILTER,
  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
  DELETE_USER,
  DELETE_USER_ERROR,
  DELETE_USER_SUCCESS,
  FILTER_USERS,
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_ERROR,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_ERROR,
  DELETE_AAD_USER_ERROR,
  DELETE_AAD_USER_SUCCESS,
  INVITE_USER,
  INVITE_USER_ERROR,
  INVITE_USER_SUCCESS,
  UNLINK_AAD_USER_ERROR,
  UNLINK_AAD_USER_SUCCESS,
  GET_ALL_USERS,
  TOGGLE_SHOW_CREATE_USER_FORM,
  UNLINK_AAD_USER,
  CLOSE_DEFAULT_ROLES_MODAL,
  SET_CREATED_USER,
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_ERROR,
  GET_USERS_BY_COMPANY,
  GET_USERS_BY_COMPANY_SUCCESS,
  GET_USERS_BY_COMPANY_ERROR,
} from './constants'

import {
  SET_USER_ROLES,
  SET_USER_ROLES_ERROR,
  SET_USER_ROLES_SUCCESS,
  UPDATE_USER_SUCCESS,
} from 'containers/User/constants'

const initialState = fromJS({
  users: List(),
  loading: false,
  companyLoading: [],
  companyErrors: new Map(),
  error: false,
  showCreateUserForm: false,
  filteredUsers: undefined,
  showAddDefaultRolesModal: false,
  createdUser: undefined,
  selectedUser: undefined,
  selectedUserLoading: false,
  usersByCompany: new Map(),
})

function usersReducer(state = initialState, action) {
  switch (action.type) {
    case CLOSE_DEFAULT_ROLES_MODAL:
      return state
        .set('createdUser', undefined)
        .set('showAddDefaultRolesModal', false)
    case GET_ALL_USERS:
    case UNLINK_AAD_USER:
    case CREATE_USER:
    case DELETE_USER:
    case GET_USERS:
    case INVITE_USER:
      return state.set('loading', true).set('error', false)
    case SET_USER_ROLES: {
      if (!action.view === 'users') return state
      return state.set('loading', true).set('error', false)
    }

    case SET_CREATED_USER:
      return state.set('createdUser', action.user)
    case GET_USERS_BY_COMPANY:
      return state.set(
        'companyLoading',
        state.get('companyLoading').push(action.companyCode)
      )

    case FILTER_USERS:
      return state.set('filteredUsers', action.filteredUsers)

    case CLEAR_FILTER:
      return state.set('filteredUsers', undefined)

    case GET_USERS_SUCCESS:
    case GET_ALL_USERS_SUCCESS:
    case INVITE_USER_SUCCESS:
      return state
        .set('loading', false)
        .set('users', List(action.users.map((user) => new UserRecord(user))))

    case SET_USER_ROLES_SUCCESS: {
      if (!action.view === 'users') return state
      return state
        .set('loading', false)
        .set('showAddDefaultRolesModal', false)
        .set('createdUser', undefined)
    }

    case GET_USERS_BY_COMPANY_SUCCESS:
      const { companyCode, users } = action
      const updatedUsersByCompany = fromJS(
        users.map((user) => new UserRecord(user))
      )
      // Update the state with the new users list for the companyId
      return state
        .setIn(['usersByCompany', companyCode], updatedUsersByCompany)
        .set(
          'companyLoading',
          state.get('companyLoading').filter((id) => id !== companyCode)
        )
    case CREATE_USER_SUCCESS:
      const createdUser = new UserRecord(action.user)
      return state
        .set('showCreateUserForm', false)
        .set('loading', false)
        .set('showAddDefaultRolesModal', true)
        .set('createdUser', createdUser)
        .set('users', state.get('users').push(createdUser))
        .set('usersByCompany', new Map())

    case DELETE_USER_SUCCESS:
      return state
        .set('loading', false)
        .set(
          'users',
          state.get('users').filter((user) => user.id !== action.userId)
        )
        .set('usersByCompany', new Map())

    case DELETE_AAD_USER_SUCCESS:
      const filteredSubUsers = state
        .get('users')
        .flatMap((users) => users.get('subUsers'))
        .filter((user) => user.aadUserId === action.userId)
      return state.set('loading', false).set(
        'users',
        state
          .get('users')
          .filter((user) => user.id !== action.userId)
          .concat(filteredSubUsers.map((user) => new UserRecord(user)))
      )

    case UNLINK_AAD_USER_SUCCESS:
      const { userId, allUsersView } = action
      const filteredSubUser = state
        .get('users')
        .flatMap((users) => users.get('subUsers'))
        .find((user) => user.id === userId)
      if (allUsersView)
        return state.set('loading', false).set(
          'users',
          state
            .get('users')
            .map((users) =>
              users.update('subUsers', (subUsers) =>
                subUsers.filter((user) => user.id !== userId)
              )
            )
            .push(new UserRecord(filteredSubUser))
        )
      else
        return state
          .set('loading', false)
          .set(
            'users',
            state
              .get('users')
              .map((user) =>
                user.id !== userId
                  ? user
                  : user.set('aadUserEmail', null).set('aadUserId', null)
              )
          )

    case SET_USER_ROLES_ERROR: {
      const { error } = action
      if (!error.view === 'users') return state
      return state
        .set('loading', false)
        .set('error', error)
        .set('showAddDefaultRolesModal', false)
        .set('createdUser', undefined)
    }

    case INVITE_USER_ERROR:
    case DELETE_USER_ERROR:
    case DELETE_AAD_USER_ERROR:
    case CREATE_USER_ERROR:
    case GET_ALL_USERS_ERROR:
    case GET_USERS_ERROR:
    case UNLINK_AAD_USER_ERROR:
      return state.set('loading', false).set('error', action.error)

    case GET_USERS_BY_COMPANY_ERROR:
      return state
        .setIn(['companyErrors', action.companyCode], action.error)
        .set(
          'companyLoading',
          state.get('companyLoading').filter((id) => id !== action.companyCode)
        )

    case TOGGLE_SHOW_CREATE_USER_FORM:
      return state.set('showCreateUserForm', !state.get('showCreateUserForm'))

    // Actions from other containers

    case UPDATE_USER_SUCCESS:
      return state
        .set(
          'users',
          state
            .get('users')
            .filter((user) => user.id !== action.user.id)
            .push(new UserRecord(action.user))
        )
        .set('selectedUser', new UserRecord(action.user))
        .set('usersByCompany', new Map())

    case GET_USER:
      return state.set('selectedUserLoading', true).set('error', false)
    case GET_USER_SUCCESS:
      return state
        .set('selectedUserLoading', false)
        .set('error', false)
        .set('selectedUser', new UserRecord(action.user))
    case GET_USER_ERROR:
      return state.set('selectedUserLoading', false).set('error', action.error)

    default:
      return state
  }
}

export default usersReducer
